interface CareerProps {
    event: string,
    date?: string,
    institution: string
}

export default [
    {
        event: 'Curso de Informática Básica',
        institution: 'Idema'
    },
    {
        event: 'Ensino Médio completo',
        date: '2015',
        institution: 'Escola Estadual Bolivar Boanerges da Silveira'
    },
    {
        event: 'Estágio de Desenvolvimento Web',
        date: 'Agosto/2019-Dezembro/2019',
        institution: 'Prefeitura Municipal de Alterosa'
    },
    {
        event: 'Bacharel em Ciência da Computação',
        date: '2016-2019',
        institution: 'UNIFENAS'
    },
    {
        event: 'Estágiario Full Stack',
        date: 'Setembro/2019-Dezembro/2019',
        institution: 'Prefeitura Munincipal de Alterosa'
    },
    {
        event: 'Desenvolvedor frontend',
        date: 'Agosto/2020-Março/2021',
        institution: 'BrasilSync System and Software'
    },
    {
        event: 'Desenvolvedor mobile com foco na abordagem offline first',
        date: 'Março/2021-Junho/2022',
        institution: 'A2W Tecnologia e Inovações'
    },
    {
        event: 'Software Developer Analyst',
        date: 'Junho/2022-Presente',
        institution: 'Ivory IT'
    }
] as CareerProps[];