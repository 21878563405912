import { makeStyles } from "@material-ui/core";
import Colors from "../../../../../../theme/colors";

const useStyles = makeStyles(theme => ({
    '@keyframes slideInFromLeft': {
        "0%": {
            transform: "translateX(-100%)"
        },
        "100%": {
            transform: "translateX(0%)"
        }
    },
    card: {
        margin: '10px 0',
        backgroundColor: Colors.secondaryDarken3ReducedOpacity,
        borderRadius: 10,
        overflow: 'hidden'
    },

    title: {
        margin: '8px 14px',
        color: Colors.primaryLighten
    },

    progressBar: {
        height: 3,
        border: '0.5px solid cyan',
        borderRadius: '0px 1.5px 1.5px 0px',
        backgroundColor: Colors.primary,
        animation: `$slideInFromLeft 350ms ${theme.transitions.easing.easeIn}`
    }
}));

export { useStyles };