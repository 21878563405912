import ProjectProps from '../common/interfaces/ProjectProps';

export default [
    { //27
        title: 'DW Barbershop: O app perfeito para a sua barbearia',
        description: `Um aplicativo completo para gerenciamento de funcionários
            e agenda de barbearias`,
        techs: ['Dart', 'Flutter', 'Riverpod', 'Shared Preferences'],
        image: require('../assets/dw_barbershop.jpg'),
        github_url: 'https://github.com/luizaugustoventura/dart_week_11'
    },
    
    { //26
        title: 'Full Cycle Invest',
        description: `Sistema que simula uma bolsa de valores construído com as mais modernas
            tecnologias utilizadas hoje`,
        techs: ['Docker', 'Golang', 'Apache Kafka', 'NestJS', 'NextJS', 'TypeScript', 'Prisma ORM'],
        image: require('../assets/full_cycle_invest.jpg'),
        github_url: 'https://github.com/luizaugustoventura/imersao-fc-bolsa'
    },

    { //25
        title: 'Microserviço de notificações',
        description: `Construído com NestJS e seguindo conceitos de TDD, DDD e Clean Architecture, é um
            microserviço que consome mensagens no Apache Kafka e armazena em um banco de dados`,
        techs: ['NestJS', 'TypeScript', 'Jest', 'Prisma ORM', 'Apache Kafka', 'Docker'],
        image: require('../assets/microservico_notificacoes.png'),
        github_url: 'https://github.com/luizaugustoventura/ignite-lab-nest'
    },

    { //24
        title: 'Codelivery: Rastreamento em tempo real',
        description: `Rastreie veículos ou mercadorias em tempo real com as tencologias mais rápidas 
            e performáticas do mercado`,
        techs: ['Golang', 'TypeScript', 'NestJS', 'ReactJS', 'WebSocket', 'Docker', 'MongoDB',
            'Apache Kafka', 'Kafka Connect', 'Kubernetes', 'ElasticSearch', 'Kibana'],
        image: require('../assets/codelivery.jpg'),
        github_url: 'https://github.com/luizaugustoventura/imersao-fc-code-delivery'
    },

    { //23
        title: 'Happy: Conectando heróis',
        description: 'Encontre um orfanato próximo, faça uma visita e torne-se o herói de uma criança',
        techs: ['ReactJS', 'React Native', 'Expo CLI', 'Node.JS', 'TypeORM', 'Yup', 'TypeScript'],
        image: require('../assets/happy.jpg'),
        github_url: 'https://github.com/luizaugustoventura/nlw3'
    },

    { //22
        title: 'Proffy: Uma rede de professores e alunos independentes',
        description: `Proffy é uma plataforma que conecta alunos e professores independentes desenvolvida 
            com ReactJS e React Native`,
        techs: ['ReactJS', 'React Native', 'Expo CLI', 'Node.JS', 'Knex', 'TypeScript'],
        image: require('../assets/proffy.png'),
        github_url: 'https://github.com/luizaugustoventura/nlw2'
    },

    { //21
        title: 'Historical F1',
        description: `Desenvolvido com React Native e Expo, o Historical F1 é um app informativo que 
            busca dados de todas as corridas da Formula 1, desde seu surgimento em 1950 até os dias 
            atuais`,
        techs: ['React Native', 'Expo CLI', 'TypeScript'],
        image: require('../assets/historical_f1.png'),
        github_url: 'https://github.com/luizaugustoventura/historical-f1'
    },

    { //20
        title: 'eColeta: O jeito mais fácil de lidar com descarte de resíduos',
        description: `eColeta é um rastrador de pontos de coleta de resíduos por 
            GPS que conecta usuários e estabelecimentos que realizam a coleta desses resíduos`,
        techs: ['ReactJS', 'React Native', 'Expo CLI', 'Node.JS', 'Knex', 'TypeScript', 'Multer', 
            'Celebrate'],
        image: require('../assets/ecoleta.png'),
        github_url: 'https://github.com/luizaugustoventura/nlw1'
    },

    { //19
        title: 'Be The Hero: Para ser um herói, basta solidariedade',
        description: `Be The Hero é um projeto desenvolvido com ReactJS e React Native, cujo 
            propósito é conectar ONGs e voluntários`,
        techs: ['ReactJS', 'React Native', 'Expo CLI', 'Node.JS', 'Knex', 'Celebrate', 'Jest'],
        image: require('../assets/be_the_hero.jpg'),
        github_url: 'https://github.com/luizaugustoventura/omnistack11'
    },

    { //18
        title: 'DevRadar: Encontre o dev que você tanto precisa',
        description: `O DevRadar é um rastreador de desenvolvedores filtrados por tecnlogias 
            desenvolvido com ReactJS e React Native`,
        techs: ['ReactJS', 'React Native', 'Expo CLI', 'Node.JS', 'Socket.IO', 'MongoDB', 'Mongoose'],
        image: require('../assets/devradar.png'),
        github_url: 'https://github.com/luizaugustoventura/omnistack10'
    },

    { //17
        title: 'Tindev: Encontre o dev perfeito',
        description: `O Tindev é uma aplicação Tinder-style que conecta desenvolvedores a partir da API 
            do GitHub, seja para encontrar um dev para sua empresa ou buscar um sócio para fundar sua 
            startup tecnológica`,
        techs: ['ReactJS', 'React Native', 'Node.JS', 'Socket.IO', 'MongoDB', 'Mongoose'],
        image: require('../assets/tindev.jpg'),
        github_url: 'https://github.com/luizaugustoventura/omnistack8'
    },

    { //16
        title: 'GerenciaOS: Gerenciador de Ordens de Serviço',
        description: `O GerenciaOS foi criado com o objetivo de automatizar o registro e o atendimento 
            de ordens de serviços de vários departamentos da Prefeitura de Alterosa, serviço que antes 
            era feito de forma manual via telefone`,
        techs: ['Angular', 'Firabase', 'ngBootstrap'],
        image: require('../assets/gerencia_os.jpg'),
        github_url: 'https://github.com/luizaugustoventura/gerencia-os'
    },

    { //15
        title: 'EletroStore: eCommerce com Angular e MongoDB',
        description: `Website desenvolvido com Angular. Trata-se de um eCommerce completo incluindo a 
            interface de cliente e também a de administrador, onde é possível gerenciar produtos, 
            clientes e vendas`,
        techs: ['Angular', 'Node.JS', 'Mongoose', 'MongoDB', 'ngBootstrap'],
        developers: ['Willian Pereira Mariano'],
        image: require('../assets/eletro_store.jpg'),
        github_url: 'https://github.com/luizaugustoventura/eletrostore-ecommerce-angular'
    },


    { //14
        title: 'Sistema de Controle Financeiro',
        description: `Aplicação WEB para controlar contas a pagar/receber, cujo objetivo era implementar 
            medidas de segurança contra SQL Injections e realizar testes automatizados`,
        techs: ['Angular', 'Node.JS', 'MySQL', 'Sequelize', 'Jasmine', 'Karma', 'ngBootstrap'],
        developers: ['Willian Pereira Mariano'],
        image: require('../assets/controle_financeiro.jpg'),
        github_url: 'https://github.com/luizaugustoventura/seg-qual'
    },

    { //13
        title: 'UPaqui: Aplicação Ionic voltada para Utilidade Pública',
        description: `Aplicação Ionic voltada para a área de utilidade pública. Usuários podem registrar 
            reclamações com foto acerca de situações de descaso e irresponsabilidade por parte de 
            instituições públicas`,
        techs: ['Ionic Framework', 'Cordova', 'Google FireBase'],
        developers: ['Willian Pereira Mariano'],
        image: require('../assets/upaqui.jpg'),
        github_url: 'https://github.com/luizaugustoventura/projeto-ionic'
    },

    { //12
        title: 'Jogo Boat Escape',
        description: `Jogo 3D desenvolvido com a API OpenGL utilizando recursos de iluminação e translação. O objetivo é desviar de icebergs, e, enquanto não há colisão, o jogador pontua`,
        techs: ['C#', 'OpenGL', 'TaoFramework'],
        developers: ['Willian Pereira Mariano'],
        image: require('../assets/boat_escape.png'),
        github_url: 'https://github.com/luizaugustoventura/boat-escape-opengl'
    },

    { //11
        title: 'Agile Home: Sistema de Automação Residencial',
        description: `Protótipo de um sistema de automação residencial onde foram automatizados a iluminação e o fluxo de água da piscina, além da possibilidade de acesso à câmeras de vigilância. Todo o gerenciamento é feito através de uma aplicação móvel`,
        techs: ['Raspberry Pi', 'Arduino', 'C++', 'Python', 'JavaScript', 'PHP', 'MySQL'],
        developers: ['Carlos Roberto dos Reis', 'João Paulo da Silva', 'Thiago Henrique da Silva'],
        image: require('../assets/automacao_residencial.jpg'),
        github_url: 'https://github.com/joaopauloexcel/automacao-residencial'
    },

    { //10
        title: 'Analisador Léxico em C#',
        description: `A etapa de análise léxica de um compilador consiste na leitura de um fluxo de 
            caracteres e agrupamento destes em lexemas, para então produzir uma sequência de tokens 
            como saída, de modo que o analisador sintático possa processar esses tokens de acordo com 
            a gramática da linguagem`,
        techs: ['C#'],
        developers: ['Willian Pereira Mariano'],
        image: require('../assets/analisador_lexico.png'),
        github_url: 'https://github.com/luizaugustoventura/analisador-lexico'
    },

    { //9
        title: 'Restaurante TremBão',
        description: `Website para gerenciar pedidos de um restaurante pequeno. O projeto foi 
            desenvolvido em JavaServer Faces com API Hibernate para fazer o mapeamento Objeto-Relacional 
            do modelo para o banco de dados MySQL`,
        techs: [
            'JavaServer Faces',
            'Java Persistence API',
            'Hibernate Framework',
            'PrimeFaces',
            'Materialize Framework',
            'JQuery',
            'MySQL'
        ],
        developers: ['Diogo Machado Miranda', 'Willian Pereira Mariano'],
        image: require('../assets/restaurante_trembao.jpg'),
        github_url: 'https://github.com/luizaugustoventura/restaurante-trembao-jsf'
    },

    { //8
        title: 'Rotulação de Componentes Conexos',
        description: `Programa que efetua a rotulação e contagem de componentes conexos em uma imagem a 
            partir da vizinhança de seus pixels`,
        techs: ['C#'],
        developers: ['Willian Pereira Mariano'],
        image: require('../assets/rotulacao_componentes_conexos.jpg'),
        github_url: 'https://github.com/luizaugustoventura/rotulacao-componentes-conexos'
    },

    { //7
        title: 'Equalização de Histogramas',
        description: `Em Computação Gráfica, o histograma de uma imagem é uma estrutura que representa 
            a frequência em que cada valor de intensidade de um canal(R,G ou B) aparece em uma imagem. A 
            partir desta estrutura, podemos identificar o nível contraste de uma imagem e melhorá-lo`,
        techs: ['C#'],
        developers: ['Willian Pereira Mariano'],
        image: require('../assets/equalizacao_histograma.jpg'),
        github_url: 'https://github.com/luizaugustoventura/equalizacao-histograma'
    },

    { //6
        title: 'Métodos de Ordenação e Pesquisa',
        description: `Trabalho que estuda e compara a performance entre os métodos de ordenação e 
            pesquisa Selection Sort, Bubble Sort, Shell Sort e Quick Sort`,
        techs: ['C#'],
        developers: ['Willian Pereira Mariano'],
        image: require('../assets/metodos_ordenacao_pesquisa.jpg'),
        github_url: 'https://github.com/luizaugustoventura/metodos-ordenacao-pesquisa'
    },

    { //5
        title: 'Interações Medicamentosas',
        description: `Aplicação Web desenvolvida em ASP.NET com arquitetura MVC que possibilita ao 
            usuário pesquisar a descrição de medicamentos bem como verificar interações entre os mesmos. 
            Este projeto é construído sob a abordagem Database First, onde o modelo é constrúido a partir 
            da estrutura do banco de dados, através do Entity Framework`,
        techs: ['ASP.NET', 'Entity Framework', 'Materialize Framework', 'JQuery', 'Microsoft SQL Server'],
        developers: ['Willian Pereira Mariano'],
        image: require('../assets/interacoes_medicamentosas.png'),
        github_url: 'https://github.com/luizaugustoventura/interacoes-medicamentosas'
    },

    { //4
        title: 'CRUD em ASP.NET MVC',
        description: `Projeto que implementa um CRUD em ASP.NET com arquitetura MVC. Este projeto estuda 
            a abordagem Model First, onde o banco de dados é criado com base no modelo através do Entity 
            Framework`,
        techs: [
            'ASP.NET',
            'Entity Framework',
            'Materialize Framework',
            'JQuery', 'Microsoft SQL Server Express'
        ],
        developers: ['Willian Pereira Mariano'],
        image: require('../assets/asp_mvc_crud.png'),
        github_url: 'https://github.com/luizaugustoventura/asp-mvc-crud'
    },

    { //3
        title: 'Jogo da Matemática',
        description: `Jogo desenvolvido em C# onde o jogador lida com operações aritméticas báscias em 
            dois níveis de dificuldade, fácil e difícil. O jogo ainda impelementa um ranking de 
            pontuação dos jogadores`,
        techs: ['C#'],
        image: require('../assets/jogo_matematica.png'),
        github_url: 'https://github.com/luizaugustoventura/jogo-matematica'
    },

    { //2
        title: 'Sistema de gerenciamento de um provedor de internet',
        description: `Projeto desenvolvido com C# e Microsoft SQL Server para gerenciar clientes, 
            dispositivos de rádio, torres e planos de internet de um provedor de internet via rádio`,
        techs: ['C#', 'Microsoft SQL Server'],
        developers: ['Willian Pereira Mariano'],
        image: require('../assets/provedor_internet.jpg'),
        github_url: 'https://github.com/luizaugustoventura/provedor-internet'
    },

    { //1
        title: 'Sys Açaí',
        description: `Programa desenvolvido em C++ para suprir a necessidade de gerenciamento de 
            produtos e fornecedores de recursos de uma pequena sorveteria. O objetivo principal do 
            projeto foi estudar a manipulação de arquivos .txt e .dat através da linguagem C`,
        techs: ['C++'],
        developers: ['Willian Pereira Mariano'],
        image: require('../assets/sys_acai.png'),
        github_url: 'https://github.com/luizaugustoventura/sys-acai'
    }
] as ProjectProps[];