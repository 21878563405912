import React, { useState } from 'react';
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import ProjectsData from '../../../../data/projects';
import ProjectDialog from './ProjectDialog';
import ProjectProps from '../../../../common/interfaces/ProjectProps';

const Projects = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [projectData, setProjectData] = useState<ProjectProps>();

    function handleCloseDialog() {
        setIsDialogOpen(false);
    }

    const styles = useStyles();

    function handleCallDialog(project: ProjectProps) {
        setIsDialogOpen(true);
        setProjectData(project);     
    }

    return (
        <>
            <Grid container spacing={3}>
                { ProjectsData.map((projectItem, itemIndex) => (
                    <Grid key={itemIndex} item xs={12} md={6} lg={4}>
                        
                        <Card className={styles.card} onClick={() => handleCallDialog(projectItem)}>
                            <CardActionArea>
                                <CardMedia 
                                    component="img"
                                    alt={projectItem.title}
                                    height="140"
                                    image={projectItem.image}
                                    title={projectItem.title}
                                />

                                <CardContent>
                                    <Typography 
                                        className={styles.cardTitle} 
                                        gutterBottom 
                                        variant="h5" 
                                        component="h2"
                                    >
                                        {projectItem.title}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    
                    </Grid>
                ))}
            </Grid>
            { projectData && (
                <ProjectDialog isOpen={isDialogOpen} handleCloseDialog={handleCloseDialog} project={projectData} />
            )}
            
        </>
    );
}

export default Projects;

