import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/colors';

const useStyles = makeStyles(() => ({
    background: {
        backgroundImage: `url('${require('../../assets/background.jpg')}')`,
        backgroundSize: 'cover',
        backgroundColor: Colors.secondaryDarken2,
    },

    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.65)'
    },

    subContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '96%',
        maxHeight: 700,
        width: '92%',
        maxWidth: 1000,
        overflow: 'auto'  
    },

    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center'
    },

    photo: {
        objectFit: 'cover',
        alignSelf: 'center',
        width: 280,
        height: 280,
        borderRadius: '50%',
        marginBottom: 12,
        marginTop: 32,
        '@media (max-width: 600px)': {
            width: 220,
            height: 220
        }
    },

    infoText: {
        fontSize: 16,
        color: Colors.primaryLighten,
        marginTop: 16,
        '@media (max-width: 600px)': {
            fontSize: 14,
            marginTop: 8
        }
    },

    infoBoldText: {
        color: Colors.primary
    },

    download: {
        display: 'flex',
        justifyContent: 'flex-end',
        '@media (max-width: 600px)': {
            marginTop: 4,
            fontSize: 14,
        }
    },

    downloadText: {
        color: Colors.primary,
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            opacity: 0.7
        }
    },

    downloadIcon: {
        marginLeft: 4
    },

    socialIcons: {
        transition: 'transform 300ms',
        '&:hover': {
            transform: 'translateY(-4px)'
        }
    }
}))


export { useStyles };