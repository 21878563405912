import { createMuiTheme } from '@material-ui/core';
import Colors from './colors';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: Colors.primary
        },
        secondary: {
            //main: '#FAFAFA'
            main: '#FFF'
        }
    }
});

export default theme;