import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { useStyles } from "./styles";
import KnowledgeData from "../../../../data/knowledge";
import Icon from "../../../../utils/Icon";
import KnowledgeItem from "./subComponents/KnowledgeItem";
import Colors from "../../../../theme/colors";

const Knowledge = () => {
    const styles = useStyles();

    return (
        <Grid container spacing={3}>
            {KnowledgeData.map((knowledgeItem, itemIndex) => (
                <Grid key={itemIndex} item xs={12} md={6} lg={4}>
                    <Paper className={styles.card} elevation={3}>
                        <div className={styles.cardHeader}>
                            <Icon
                                name={knowledgeItem.icon}
                                size={40}
                                color={Colors.primary}
                            />
                            <h2 className={styles.cardTitle}>{knowledgeItem.title}</h2>
                        </div>
                        <div className={styles.cardContent}>
                            {knowledgeItem.items.map((listItem, listIndex) => (
                                <KnowledgeItem
                                    title={listItem.title}
                                    level={listItem.level}
                                    key={listIndex}
                                />
                            ))}
                        </div>
                    </Paper>
                </Grid>
        )   )}
        </Grid>
    );
};

export default Knowledge;