import { makeStyles } from '@material-ui/core';
import Colors from '../../../../theme/colors';

const useStyles = makeStyles(() => ({
    card: {
        borderRadius: 10,
        height: '100%',
        backgroundColor: Colors.secondaryDarken2ReducedOpacity2,
        transition: 'transform 300ms',
        '&:hover': {
            transform: 'translateY(-8px)'
        }
    },

    cardTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: Colors.primaryLighten
    }
}));

export { useStyles };