import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import ProjectProps from '../../../../../common/interfaces/ProjectProps';
import { useStyles } from './styles';

interface DialogProps {
    isOpen: boolean,
    handleCloseDialog: () => void,
    project: ProjectProps
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectDialog: React.FC<DialogProps> = ({ isOpen, handleCloseDialog, project }) => {
    const styles = useStyles(); 

    return (
        <Dialog
            classes={{ 
                paper: styles.dialog
            }}
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle 
                className={styles.titleContainer} 
                id="alert-dialog-slide-title"
            >
                <Typography 
                    className={styles.title}
                    variant="h6"
                >
                    {project.title}
                </Typography>
            </DialogTitle>

            <DialogContent className={styles.content}>
                <img className={styles.contentImage} src={project.image} alt={project.title} />
                <DialogContentText 
                    className={styles.contentText} 
                    id="alert-dialog-slide-description"
                >
                    <Typography 
                        className={styles.contentTextSection}
                    >
                        {project.description}
                    </Typography>
                    
                    <Typography className={styles.contentTextSection}>
                        <b>Tecnologias: </b>
                        {project.techs.join(', ')}
                    </Typography>

                    { project.developers && (
                        <Typography className={styles.contentTextSection}>
                            <b>Outros desenvolvedores: </b>
                            {project.developers.join(', ')}
                        </Typography>
                    ) }
                    
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    className={styles.closeButton} 
                    onClick={handleCloseDialog} 
                    color="inherit"
                >
                    <b>Fechar</b>
                </Button>
                <Button 
                    className={styles.actionButton}
                    href={project.github_url} 
                    target="blank" 
                    color="primary"
                >
                    <b>Acessar no GitHub</b>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProjectDialog;