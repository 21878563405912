export default {
    primary: '#62C2E1',
    primaryReducedOpacity: 'rgba(98, 194, 225, 0.6)',
    primaryLighten: '#A2B9C1',
    secondary: '#E5E5E5',
    secondaryLighten: '#F5F5F5',
    secondaryDarken: '#555555',
    secondaryDarken2: '#2E2E2E',
    secondaryDarken3: '#1D1D1D',
    secondaryDarken2ReducedOpacity: 'rgba(46, 46, 46, 0.8)',
    secondaryDarken2ReducedOpacity2: 'rgba(46, 46, 46, 0.7)',
    secondaryDarken2ReducedOpacity3: 'rgba(46, 46, 46, 0.4)',
    secondaryDarken3ReducedOpacity: 'rgba(31, 31, 31, 0.7)'
}
