import React from "react";
import { Paper } from "@material-ui/core";
import { useStyles } from "./styles";

interface KnowledgeItemProps {
    title: string;
    level: number;
}

const KnowledgeItem: React.FC<KnowledgeItemProps> = ({ title, level }) => {
    const styles = useStyles();

    return (
        <Paper elevation={3} className={styles.card}>
            <h5 className={styles.title}>{title}</h5>

            <div className={styles.progressBar} style={{ width: `${level}%` }} />
        </Paper>
    );
};

export default KnowledgeItem;