import { makeStyles } from '@material-ui/core';
import Colors from '../../../../theme/colors';

const useStyles = makeStyles({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },

    paper: {
        display: 'flex',
        backgroundColor: Colors.secondaryDarken2ReducedOpacity,
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 12,
        padding: 12,
        borderRadius: 10,
        transition: 'transform 300ms',
        '&:hover': {
            transform: 'translateX(8px)'
        }
    },

    paperText: {
        color: Colors.primaryLighten
    },

    icon: {
        marginLeft: 16
    }
});

export { useStyles };