import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { FaTrophy } from 'react-icons/fa';
import { useStyles } from './styles';
import Colors from '../../../../theme/colors';
import CareerData from '../../../../data/career';

const Career = () => {
    const styles = useStyles();

    return (
        <div className={styles.mainContainer}>
            { 
                CareerData.map((careerItem, itemIndex) => (
                    <Paper key={itemIndex} className={styles.paper} elevation={3}>
                        <Typography className={styles.paperText}>
                            {
                                `
                                    ${careerItem.event} 
                                    ${careerItem.date ? `(${careerItem.date})` : ''} - 
                                    ${careerItem.institution}
                                `
                            }
                        </Typography>
                        <div className={styles.icon}>
                            <FaTrophy size={22} color={Colors.primary} />
                        </div>
                    </Paper>
                ))
            }
        </div>
    );
}

export default Career;