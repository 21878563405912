import React from 'react';
import { FaWrench, FaGlobe, FaMobileAlt, FaServer, FaDatabase, FaTools, FaLanguage, FaQuestion } from 'react-icons/fa';

interface IconProps {
    name: string,
    color: string,
    size: number
}

const Icon: React.FC<IconProps> = ({ name, color, size }) => {
    switch(name) {
        case 'wrench': 
            return <FaWrench size={size} color={color} />
        case 'globe': 
            return <FaGlobe size={size} color={color} />
        case 'mobile-alt': 
            return <FaMobileAlt size={size} color={color} />
        case 'server': 
            return <FaServer size={size} color={color} />
        case 'database': 
            return <FaDatabase size={size} color={color} />
        case 'tools': 
            return <FaTools size={size} color={color} />
        case 'language': 
            return <FaLanguage size={size} color={color} />
        default: 
            return <FaQuestion size={size} color="#E5E5E5" />
    }
} 

export default Icon;