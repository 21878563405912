import { makeStyles } from '@material-ui/core';
import Colors from '../../theme/colors';

const useStyles = makeStyles(() => ({
    background: {
        backgroundImage: `url('${require('../../assets/background2.jpg')}')`,
        backgroundSize: 'cover',
        backgroundColor: Colors.secondaryLighten
    },

    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.65)'
    },    
    
    subContainer: {
        height: '96%',
        maxHeight: 700,
        width: '92%',
        maxWidth: 1000, 
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundColor: Colors.secondaryDarken2ReducedOpacity3
    },

    appBarBackground: {
        backgroundColor: Colors.primaryReducedOpacity
    },

    tabLabel: {
        fontWeight: 'bold',
        color: Colors.secondary,
        '@media (max-width: 600px)': {
            fontSize: 12
        }
    }
}));

export { useStyles };