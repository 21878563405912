import React from 'react';
import { FaCloudDownloadAlt, FaFacebook, FaLinkedin, FaInstagram, FaGithubSquare, FaWhatsapp } from 'react-icons/fa';
import Colors from '../../theme/colors';
import { useStyles } from './styles';

import picture from '../../assets/picture.jpg';

function navigateToPrimaryPanel() {
    window.location.href = '#primary-panel';
}

const PrimaryPanel  = () => {
    const styles = useStyles();

    return (
        <div id="primary-panel" className={styles.background} onClick={() => navigateToPrimaryPanel()}>
            <div className={styles.container}>
                <div className={styles.subContainer}>

                    <img src={picture} alt="Este sou eu. Gostou?" className={styles.photo} />

                    <div className={styles.infoContainer}>
                        

                        <p className={styles.infoText}>
                            Olá, meu nome é Luiz Augusto Ventura, atualmente trabalho como desenvolvedor mobile
                            e sempre fui apaixonado por tecnologia. Antes de terminar o ensino médio,
                            eu já havia decidido que iria trilhar meu futuro na área de TI. Foi então que,
                            logo após finalizar o ensino médio, iniciei meus estudos no curso de Ciência de Computação da UNIFENAS,
                            em 2016, na cidade de Alfenas-MG.
                        </p>
                        <p className={styles.infoText}>
                            Logo que iniciei os estudos na área, me apaixonei pela lógica e pela programação em si.
                            Busco utilizar estes fatores da melhor maneira possível para que, juntamente com o time,
                            possa resolver problemas de maneira eficiente e me tornar um melhor colaborador no ambiente empresarial.
                        </p>
                        <p className={styles.infoText}>
                            <b className={styles.infoBoldText}>Meu e-mail: </b>luizaugustoventu1998@hotmail.com
                            <br />
                            <b className={styles.infoBoldText}>Meu telefone: </b>(35) 99744 2108
                        </p>   

                        <div className={styles.download}>
                            <a 
                                className={styles.downloadText} 
                                href={require('../../docs/curriculo.pdf')}
                                download="Curriculo_Luiz_Augusto_Ventura.pdf"
                            >
                                Faça download do meu currículo
                                <FaCloudDownloadAlt 
                                    className={styles.downloadIcon} 
                                    size={20} 
                                    color={Colors.primary} 
                                />
                            </a>
                        </div>         
                    </div>

                    <div>
                        <a href="https://www.facebook.com/luizaugusto.ventura.3" target="blank">
                            <FaFacebook size={40} color={Colors.primary} className={styles.socialIcons} />
                        </a>
                        &nbsp;
                        <a href="https://www.linkedin.com/in/luizaugustoventura" target="blank">
                            <FaLinkedin size={40} color={Colors.primary} className={styles.socialIcons} />
                        </a>
                        &nbsp;
                        <a href="https://www.instagram.com/luizaugustoventura/" target="blank">
                            <FaInstagram size={40} color={Colors.primary} className={styles.socialIcons} />
                        </a>
                        &nbsp;
                        <a href="https://github.com/luizaugustoventura#" target="blank">
                            <FaGithubSquare size={40} color={Colors.primary} className={styles.socialIcons} />
                        </a>
                        &nbsp;
                        <a href="http://api.whatsapp.com/send?phone=553597442108" target="blank">
                            <FaWhatsapp size={40} color={Colors.primary} className={styles.socialIcons} />
                        </a>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default PrimaryPanel;