interface KnowledgeProps {
    icon: string,
    title: string,
    items: {
        title: string,
        level: number
    }[]
}

export default [
    { //1
        icon: 'mobile-alt',
        title: 'Frontend',
        items: [
            { title: 'ReactJS', level: 95 },
            { title: 'React Native', level: 95 },
            { title: 'Flutter', level: 10 }
        ]
    },
    
    { //2
        icon: 'server',
        title: 'Backend',
        items: [
            { title: 'NodeJS (REST com Express)', level: 70 },
            { title: 'NestJS', level: 20 }
        ]
    },
 
    { //3
        icon: 'tools',
        title: 'DevOps',
        items: [
            { title: 'Docker', level: 95 },
            { title: 'Kubernetes', level: 55 },
            { title: 'Azure DevOps', level: 20 },
            { title: 'Google Cloud Platform', level: 35 }
        ]
    },
   
    
    { //4
        icon: 'language',
        title: 'Idiomas',
        items: [
            { title: 'Português', level: 100 },
            { title: 'Inglês', level: 100 },
            { title: 'Espanhol', level: 20 }
        ]
    },
   
    { //5
        icon: 'globe',
        title: 'Outros',
        items: [
            { title: 'Arduino', level: 60 },
            { title: 'Raspberry Pi', level: 30 },
            { title: 'Node MCU', level: 25 }
        ]
    },
] as KnowledgeProps[];
