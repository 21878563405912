import { makeStyles } from '@material-ui/core';
import Colors from '../../../../theme/colors';

const useStyles = makeStyles(() => ({
    card: {
        display: 'flex',
        height: '100%',
        backgroundColor: Colors.secondaryDarken2ReducedOpacity2,
        flexDirection: 'column', 
        borderRadius: 10, 
        transition: 'transform 300ms',
        '&:hover': {
            transform: 'translateY(-8px)'
        }
    },

    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 16
    },

    cardTitle: {
        flex: 1,
        textAlign: 'center',
        color: Colors.primary,
        fontSize: 20,
        marginLeft: 8
    },

    cardContent: {
        margin: 16
    },

    cardList: {
        listStyle: 'none',
        color: Colors.primaryLighten
    }
}));

export { useStyles };