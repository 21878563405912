import React from 'react';
import { AppBar, Tabs, Tab, Box } from '@material-ui/core';
import { Apps, MenuBook, School } from '@material-ui/icons';
import { useStyles } from './styles';

import Projects from './subComponents/Projects';
import Knowledge from './subComponents/Knowledge';
import Career from './subComponents/Career';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
  
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>
                        {children}
                    </div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const SecondaryPanel  = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const styles = useStyles();

    return (
        <div id="secondary-panel" className={styles.background}>
            <div className={styles.container}>
                <div className={styles.subContainer}>
        
                    <AppBar
                        position="static"
                        color="primary"
                        className={styles.appBarBackground}
                    >
                        <Tabs 
                            value={value} 
                            onChange={handleChange} 
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant="fullWidth"
                            scrollButtons="off"
                            centered
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab 
                                className={styles.tabLabel} 
                                label="Projetos"  
                                icon={<Apps />} 
                                {...a11yProps(0)}
                                href="#secondary-panel"
                            />
                            <Tab 
                                className={styles.tabLabel} 
                                label="Conhecimentos" 
                                icon={<MenuBook />} 
                                {...a11yProps(1)}
                                href="#secondary-panel"
                            />
                            <Tab 
                                className={styles.tabLabel} 
                                label="Carreira" 
                                icon={<School />} 
                                {...a11yProps(2)}
                                href="#secondary-panel"
                            />
                        </Tabs>
                    </AppBar>
            
                    <TabPanel value={value} index={0}>
                        <Projects />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Knowledge />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Career />
                    </TabPanel>
                </div>
            </div>
        </div>
    );
}

export default SecondaryPanel;