import { makeStyles } from '@material-ui/core';
import Colors from '../../../../../theme/colors';

const useStyles = makeStyles(() => ({
    dialog: {
        borderRadius: 10,
        backgroundColor: Colors.secondaryDarken2
    },

    titleContainer: {
        padding: 12
    },

    title: {
        color: Colors.primaryLighten,
        fontWeight: 'bold'
    },

    content: {
        padding: 0
    },

    contentImage: {
        width: '100%'
    },

    contentText: {
        margin: 24,
        '@media (max-width: 600px)': {
            margin: 16
        }, 
        color: Colors.primaryLighten
    },

    contentTextSection: {
        fontSize: 16,
        '& + $contentTextSection': {
            marginTop: 8
        }
    },

    closeButton: {
        color: '#DB0944'
    },

    actionButton: {
        filter: 'brightness(0.9)'
    }
}));

export { useStyles };