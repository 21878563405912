import React from 'react';

import PrimaryPanel from './components/PrimaryPanel';
import SecondaryPanel from './components/SecondaryPanel';


function App() {
  return (
    <>
      <PrimaryPanel />
      <SecondaryPanel/>
    </>
  );
}

export default App;
